






























































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import { SetOfDocuments } from "@/models/contract";
import { SetOfDocumentsAsReqBody } from "@/models/app-api";
import XDialog from "../SimpleDialog.vue";
import XDropdownWithDatePicker from "../hoc/DropdownWithDatePicker.vue";
import { documentGetterForm } from "@/assets/scripts/form-fields/main";
import AppApiMixin from "../mixins/AppApi.vue";
import DocGetterMixin from "../mixins/DocGetter.vue";

const { values, attrs, errors, validations } = documentGetterForm;

@Component({
  components: { XDialog, XDropdownWithDatePicker },
  mixins: [validationMixin],
  computed: {
    ...errors,
    ...mapGetters({ contractId: "contract/id", userEmail: "user/email" }),
  },
  validations,
})
class DocGetterDialog extends Mixins(AppApiMixin, DocGetterMixin, XDialog) {
  [x: string]: any;

  @Prop({ required: true }) readonly showDialog!: boolean;
  @Prop({ required: true }) readonly docNumber!: number;
  @Prop({ default: false }) readonly isSendMode!: boolean;

  values = { email: values.email, docType: "" };
  allFieldAttrs = {
    email: attrs.email,
    docType: {
      type: "select",
      label: "Документ",
      required: true,
      items: [],
    },
  };

  setOfDocuments: SetOfDocuments[] = [];

  usePreloader = false;
  useAgreeButtonLoading = false;
  submitErrorText = "";

  public get itemTexts() {
    return this.isSendMode
      ? { dialogTitle: "Отправить документ на email", agreeButton: "Отправить" }
      : { dialogTitle: "Скачать документ", agreeButton: "Скачать" };
  }

  public get fields() {
    const { docType } = this.allFieldAttrs;

    return this.isSendMode ? this.allFieldAttrs : { docType };
  }

  @Watch("showDialog")
  public showDialogChanged() {
    if (this.showDialog) {
      this.usePreloader = true;

      const { contractId, docNumber } = this;

      this.getSetOfDocumentsByContract({ contractId, document: docNumber })
        .then((fetchedSetOfDocuments) => {
          const setOfDocuments = fetchedSetOfDocuments.data.data;

          this.setOfDocuments = setOfDocuments;

          this.allFieldAttrs.docType.items = setOfDocuments.map(
            (doc: SetOfDocuments) => doc["значение"]
          );

          this.values.email = this.userEmail;
          this.values.docType = this.allFieldAttrs.docType.items[0];
        })
        .catch(console.error)
        .finally(() => (this.usePreloader = false));

      return;
    }

    this.values.email = "";
    this.values.docType = "";
    this.submitErrorText = "";

    this.$v.$reset();
  }

  public onAgree(evt: { name: string; payload: null }) {
    this.submitErrorText = "";
    this.useAgreeButtonLoading = true;

    const { contractId, isSendMode, propagateCustomEvt, values } = this;

    const document = this.setOfDocuments.find(
      (doc) => doc["значение"] === values.docType
    );
    const documentKey = document ? document["ключ"] : -1;

    const reqBody: SetOfDocumentsAsReqBody = {
      contractId,
      document: documentKey,
    };

    if (isSendMode) {
      reqBody.emailSend = true;
      reqBody.email = values.email;
    } else {
      reqBody.download = true;
    }

    this.getSetOfDocumentsByContract(reqBody)
      .then((response) => {
        if (!isSendMode) {
          this.downloadDocument(response);
          return propagateCustomEvt(evt.name);
        }
        propagateCustomEvt(evt.name, values.email);
      })
      .catch((_) => (this.submitErrorText = "Непредвиденная ошибка"))
      .finally(() => (this.useAgreeButtonLoading = false));
  }
}

export default DocGetterDialog;
