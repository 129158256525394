var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs__content"},[_c('contract-document-table',{attrs:{"documents":_vm.documentList},on:{"send":function (docId) {
        _vm.currentDocId = docId;
        _vm.currentDocName = _vm.getDocName(docId);
        _vm.showDocSendingDialog = true;
      },"download":function (docId) {
        _vm.currentDocId = docId;
        _vm.currentDocName = _vm.getDocName(docId);
        _vm.showDocDownloadingDialog = true;
      }}}),_c('contract-doc-getter-dialog',{attrs:{"show-dialog":_vm.showDocSendingDialog,"doc-id":_vm.currentDocId,"doc-name":_vm.currentDocName,"is-send-mode":true},on:{"agree":function (ref) {
        var email = ref.payload;

        _vm.closeAllDialogs();
        _vm.targetEmail = email;
        _vm.useDocSendingDialogNotice = !_vm.useDocSendingDialogNotice;
      },"disagree":_vm.closeAllDialogs}}),_c('contract-doc-getter-dialog',{attrs:{"show-dialog":_vm.showDocDownloadingDialog,"doc-id":_vm.currentDocId,"doc-name":_vm.currentDocName},on:{"agree":_vm.closeAllDialogs,"disagree":_vm.closeAllDialogs}}),_c('x-notice',{attrs:{"show":_vm.useDocSendingDialogNotice}},[_vm._v(" Документы успешно отправлены на email: "+_vm._s(_vm.targetEmail)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }